<template>
  <div class="controllerBingren">
    <div class="controller-modules-header left">病人位置</div>
    <a-select v-model:value="selectValue" placeholder="请选择">
      <template #suffixIcon>
        <img class="select_img" src="@/assets/images/ico_select.png" alt="" />
      </template>
      <a-select-option
        :value="item.code"
        v-for="(item, index) in patientPositionDict"
        :key="index"
        >{{ item.name }}</a-select-option
      >
    </a-select>
    <button class="controller-btn" @click="submit('position')">确认</button>
    <div class="controller-modules-header left">控制温度</div>
    <div class="controller-checkbox">
      <a-checkbox-group v-model:value="checkValue">
        <a-row>
          <a-col :span="8" v-for="(item, index) in controlTemperature" :key="index">
            <a-checkbox :value="item.code">{{ item.name }}</a-checkbox>
          </a-col>
        </a-row>
      </a-checkbox-group>
    </div>
    <button class="controller-btn no-margin-top" @click="submit('temperature')">
      确认
    </button>
    <div class="controller-modules-log">
      <block v-show="patientDataArray.length != 0">
        <div v-for="(item, index) in patientDataArray" :key="index">
          <div
            style="
              white-space: pre-line;
              width: 100%;
              font-size: 12px;
              color: #152454;
              line-height: 22px;
              font-weight: 400;
            "
            v-html="item"
          ></div>
        </div>
      </block>
    </div>
    <!-- <div class="controller-modules-header left">操作记录</div>
   
    <div class="controller-modules-log">
      <block v-show="actionLog.length != 0">
        <div class="item" v-for="(item, index) in actionLog" :key="index">
        【{{formateSeconds(item.logTime)}}】{{item.logContent}}
        </div>
      </block>
      <block v-show="actionLog.length == 0">
        <div class="item" >
            暂无操作记录。
        </div>
      </block>
    </div> -->
  </div>
</template>
<script>
import { mapGetters, mapActions } from "vuex";
import { formateSeconds } from "@/utils/tool";
export default {
  data() {
    return {
      selectValue: undefined,
      checkValue: "",
      formateSeconds,
      actionLog: [],
      patientDataArray: [],
    };
  },
  created() {
    this.getActionLog();
    this.getPatientDataArray();
  },
  methods: {
    ...mapActions({
      submitStep: "exma/submitStep",
    }),
    getPatientDataArray() {
      let time2 = setInterval(() => {
        if (
          !localStorage.getItem("patientDataArrayT") ||
          this.patientDataArray.length >=
            JSON.parse(localStorage.getItem("patientDataArrayT")).length
        ) {
          clearInterval(time2);
        }
        this.patientDataArray = JSON.parse(localStorage.getItem("patientDataArrayT"));
      }, 1000);
      this.patientDataArray = JSON.parse(localStorage.getItem("patientDataArrayT"));
    },
    /**
     * position 位置 selectValue
     * temperature 温度 checkValue
     */
    async submit(type) {
      let d = {
        memberCaseId: this.memberCaseId,
        component: type == "position" ? "patient_position" : "control_temperature",
        actionTime: this.getHandleTimeAt,
        otherData:
          type == "position"
            ? {
                patient_position: this.selectValue,
              }
            : {
                control_temperature: this.checkValue,
              },
      };
      this.submitStep(d).then((res) => {
        this.getActionLog();
      });
    },
    async getActionLog() {
      try {
        let res = await this.$http.post(this.$interface.examActionLog, {
          componentCategory: "patient",
          memberCaseId: this.memberCaseId,
        });
        this.actionLog = res.data.data || [];
        console.log(res);
      } catch (err) {}
    },
  },
  computed: {
    ...mapGetters([
      "patientPositionDict",
      "controlTemperature",
      "getHandleTimeAt",
      "memberCaseId",
    ]),
  },
};
</script>
<style lang="scss">
@import "../index.scss";
</style>
